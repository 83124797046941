import React from "react";
import MyImage from "../../assets/myimage.jpg";

function Image() {
  return (
    <div className="my-image mt-12 flex justify-center">
      <img
        src={MyImage}
        alt="myimage"
        className="rounded-full border-4 border-transparent"
      />
    </div>
  );
}

export default Image;