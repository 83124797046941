import ATM from "../../assets/Projects/ATM.png";
//import CountDownTimer from "../../assets/Projects/CountdownTimer.png";
import BeerStock from "../../assets/Projects/BeerStock.png";
import RealEstate from "../../assets/Projects/RealEstate.png";
import Geeky from "../../assets/Projects/Geeky.png";
import ItemStock from "../../assets/Projects/ItemStock.png";
import BookStore from "../../assets/Projects/BookStore.png";
import WebScraper from "../../assets/Projects/WebScraper.png";
import TeknoCYP from "../../assets/Projects/TeknoCYP.png";
import CareerHub from "../../assets/Projects/Careerhub.png";
import Email from "../../assets/Projects/Email.png";
/*import HTML from "../../assets/TechStack/HTML.png";
import CSS from "../../assets/TechStack/CSS.png";
import Javascript from "../../assets/TechStack/Javascript.png"; */
import CSharp from "../../assets/TechStack/CSharp.png";
import DotNet from "../../assets/TechStack/DotNet.png";
import Laravel from "../../assets/TechStack/Laravel.png";
import Nodejs from "../../assets/TechStack/Nodejs.png";
import React from "../../assets/TechStack/React.png";
import MySql from "../../assets/TechStack/Mysql.png";
import Mssql from "../../assets/TechStack/Mssql.png";
import Bootstrap from "../../assets/TechStack/Bootstrap.png";
import SQLite from "../../assets/TechStack/SQLite.png";
import NestJS from "../../assets/TechStack/NestJS.png";

export const projectList = [
    /*{
        name: "Countdown Timer",
        description: "A simple countdown timer that counts down to a specific date and time.",
        techs: [HTML, CSS, Javascript],
        image: CountDownTimer,
        github: "https://github.com/SalahiErensel/Countdown-Timer"
    }, */
    {
        name: "Beer Stock System",
        description: "A beer stock system that allows you to add, update, delete and list beers.",
        techs: [Laravel, MySql, Bootstrap],
        image: BeerStock,
        github: "https://github.com/SalahiErensel/Beer-Stock-System"
    },
    {
        name: "Real Estate Website",
        description: "Front-end of an example real estate website.",
        techs: [React, Bootstrap],
        image: RealEstate,
        github: "https://github.com/SalahiErensel/Real-Estate-"
    },
    {
        name: "Geeky App",
        description: "A banking app that users can deposit, withdraw money with registering and logging in with their private accounts.",
        techs: [Laravel, MySql, Bootstrap],
        image: Geeky,
        github: "https://github.com/SalahiErensel/Geeky-App-"
    },
    {
        name: "Item Stock System",
        description: "An item stock system that allows you to add, update, delete and list items.",
        techs: [Laravel, MySql, Bootstrap],
        image: ItemStock,
        github: "https://github.com/SalahiErensel/Item-Store-System"
    },
    {
        name: "Book Store System",
        description: "A book Store with 2 Users Admin & Seller. Admin can do all CRUD operations about books informations(Author,category,etc..). Sellers can view the stock size of their books and delete or add new book and can print the bill of the books.",
        techs: [DotNet, Mssql, Bootstrap],
        image: BookStore,
        github: "https://github.com/SalahiErensel/OnlineBookStore"
    },
    {
        name: "ATM",
        description: "An ATM that users can login with their card numbers and their card passwords. 4 Options are provided to users which are deposit, withdraw, show balance and exit.",
        techs: [CSharp],
        image: ATM,
        github: "https://github.com/SalahiErensel/ATM"
    },
    {
        name: "Web Scraper",
        description: "A web scraper that scrapes the data from the website and shows it to user.",
        techs: [CSharp],
        image: WebScraper,
        github: "https://github.com/SalahiErensel/WebScraper"
    },
    {
        name: "Tekno CYP",
        description: "A website for selling electronic products with an admin panel. System code is not open source.",
        techs: [DotNet, Mssql, Bootstrap],
        image: TeknoCYP,
        github: ""
    },
    {
        name: "Careerhub",
        description: "University graduation project. A website for job seekers and employeers to find each other. Employers can publish job advertisements and job seekers can view, filter the jobs, create themselves profile and apply for a job. System code is not open source.",
        techs: [React, NestJS, SQLite, Bootstrap],
        image: CareerHub,
        github: ""
    },
    {
        name: "Email Inbox App",
        description: "An application where users can view their mail inbox, after reading, mark them as read and response.",
        techs: [React, Nodejs, Bootstrap],
        image: Email,
        github: "https://github.com/SalahiErensel/EmailInboxApp"
    }
]